import React from 'react';
import styles from "./Circle.module.css";

export default function Circle({
  size = 'small',
  color,
  image,
  multicolor,
  ...props
}) {

  const className = multicolor === true
    ? `${styles[size]} ${styles.multicolor}`
    : styles[size];

  const style =
    image !== null &&
    image !== undefined
      ? { background: `url('${image}') center center / contain`}
      : { backgroundColor: color };

  return (
    <div
      className={className}
      style={style}
      {...props}
    />
  );
}
