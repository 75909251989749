import Inline from "../atoms/Inline";
import Circle from "../atoms/Circle";
import styles from "./ColorSelector.module.css";
import Stack from "../atoms/Stack";
import Label from "../atoms/Label";
import {useTranslation} from "react-i18next";
import useAppStore from "../hooks/useAppStore";

export default function ColorSelector({
  items,
  selected,
  disabled= false,
  disabledItems = [],
  onSelect,
  size = 'medium'
}) {

  const colorDisabled = (item) => (
    disabled || disabledItems.find( color =>
      color.name === item.name.replace(' Shiny', '').replace(' Matt', '')
    )
  );

  const { t } = useTranslation();
  const circleContainerClassName = (item) => `${styles.circleContainer} ${styles[size]} ${selected && item.id === selected.id ? styles.selected : null}`;

  return (
    <div className={styles.container}>
      <Inline gap="xlarge" verticalAlign="center">
        {items.map( item => (
          <button
            key={item.id}
            onClick={ (event) => onSelect(item) }
            disabled={colorDisabled(item)}
          >
            <Stack halign="center" valign="center">
              <div
                className={circleContainerClassName(item)}
                disabled={colorDisabled(item)}
              >
                <Circle
                  color={item.color}
                  image={item.image}
                  multicolor={item.multicolor}
                  size={size}
                />
              </div>
              <Label size="small" weight="medium">{t(`label.${item.name.toLowerCase()}`)}</Label>
            </Stack>
          </button>
        ))}
      </Inline>
    </div>
  );

}
